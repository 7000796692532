import { Fragment, useEffect, useState } from 'react'
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react'
import { useClickAnyWhere, useEventListener } from 'usehooks-ts'

import CtaLink, { buttonStyles } from '@/components/ui/cta-link'
import ImageContentful from '@/components/ui/image-contentful'

import { getBrowserName } from '@/utils/user-info'
import { cx } from '@/utils/strings'

type Browser = {
  name: string
  url: string
  icon: string
  unicode: string
  description: string
  image: string
}

type DownloadTabsProps = {
  id?: string
  headline?: string
  description?: string
  browsers: Browser[]
}

export default function DownloadTabs({ id, headline, description, browsers = [] }: DownloadTabsProps) {
  const [currentBrowser, setCurrentBrowser] = useState<string | null>(null)
  const [browserList, setBrowserList] = useState<Browser[]>(browsers)
  useEffect(() => {
    if (typeof window === 'undefined') return
    const browser = getBrowserName()
    if (browser !== 'Unknown') {
      const browserIndex = browserList.findIndex((b) => b.name === browser)
      if (browserIndex !== -1) {
        setBrowserList([browserList[browserIndex], ...browserList.filter((b) => b.name !== browser)])
      }
    }
    setCurrentBrowser(browser)
  }, [])

  const [hideOutline, setHideOutline] = useState(true)
  useClickAnyWhere(() => setHideOutline(true))
  useEventListener('keydown', () => {
    setHideOutline(false)
  })

  return (
    <section
      id={id}
      className={cx(
        'max-w-8xl mx-auto px-5 lg:px-9 transition-opacity duration-100',
        currentBrowser === null ? 'opacity-0' : 'opacity-100'
      )}
    >
      {(headline || description) && (
        <div className="mb-12">
          {headline && <h3 className="text-2xl lg:text-4xl text-center mb-4">{headline}</h3>}
          {description && <p className="text-base text-center mb-0">{description}</p>}
        </div>
      )}
      <TabGroup key={currentBrowser} className="flex flex-col items-center">
        <TabList className="bg-[#DBE5F6] rounded-3xl md:px-4 2xl:px-0 2xl:rounded-full inline-flex flex-wrap items-center justify-center max-w-[calc(clamp(40%, 800px,100%))] 2xl:max-w-none mx-auto gap-1 mb-6">
          {browserList.map((browser) => (
            <Tab as={Fragment} key={browser.icon}>
              {({ hover, selected }) => (
                <button
                  type="button"
                  className={cx(
                    'py-2 px-3 md:px-6 md:py-3 border-2 border-transparent rounded-full text-primaryBlue flex items-center transition-colors outline-none focus:ring-4 focus:ring-primaryBlue focus:ring-opacity-50',
                    selected && `bg-primaryBlue border-primaryBlue text-white`,
                    hover && `!border-primaryBlue`,
                    hideOutline && `focus:ring-0`
                  )}
                >
                  <span
                    className="font-bwi leading-[2rem] text-[2rem] mr-1 md:mr-2"
                    data-name={`bwi-${browser.icon}`}
                    dangerouslySetInnerHTML={{ __html: browser.unicode }}
                  />
                  {browser.name}
                </button>
              )}
            </Tab>
          ))}
        </TabList>
        <TabPanels className="w-full">
          {browserList.map((browser) => (
            <TabPanel key={browser.icon} className="w-full bg-gray-100 border-gray-200 text-gray-800 rounded-[20px]">
              <div className="flex flex-col-reverse lg:flex-row gap-4 p-4">
                <div className="flex-grow flex flex-col justify-center px-6 md:px-16 lg:max-w-1/2">
                  <div className="flex items-center gap-3 mb-2 md:mb-6">
                    <span
                      className="font-bwi text-[3rem]"
                      data-name={`bwi-${browser.icon}`}
                      dangerouslySetInnerHTML={{ __html: browser.unicode }}
                    />
                    <h3 className="mb-0">{browser.name}</h3>
                  </div>
                  <p className="text-lg mb-6" dangerouslySetInnerHTML={{ __html: browser.description }} />
                  <CtaLink className="self-start" to={browser.url} style={buttonStyles.btnOutlined}>
                    Download now
                  </CtaLink>
                </div>
                <ImageContentful
                  className="max-h-96 lg:w-1/2 lg:max-w-1/2 lg:h-auto lg:max-h-none"
                  publicUrl={browser.image}
                  file={{ url: browser.image, contentType: 'image/svg+xml' }}
                  title={browser.name}
                />
              </div>
            </TabPanel>
          ))}
        </TabPanels>
      </TabGroup>
    </section>
  )
}
